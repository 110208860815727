{
  "name": "enrollhere-importer",
  "version": "0.3.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:enrollhere-importer": "node dist/enrollhere-importer/server/server.mjs",
    "deploy-major": "npm version major && git add . && git push --tags && git push origin main:main",
    "deploy-minor": "npm version minor && git add . && git push --tags && git push origin main:main",
    "deploy": "npm version patch && git add . && git push --tags && git push origin main:main",
    "replace-timestamp": "node ./replace.build.js",
    "deploy:prod:temp": " ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here --config firebase.temp.json",
    "deploy:demo:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.demo.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here-demo --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "deploy:dev:temp": "mv ./src/environments/environment.ts ./src/environments/environment.prod.ts  && cp ./src/environments/environment.development.ts ./src/environments/environment.ts && ng build --c production &&  firebase deploy --only hosting:enrollhere-importer --project enroll-here-dev --config firebase.temp.json && mv ./src/environments/environment.prod.ts ./src/environments/environment.ts",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org enrollhere --project enrollhere-importer ./dist/enrollhere-importer/browser && sentry-cli sourcemaps upload --org enrollhere --project enrollhere-importer ./dist/enrollhere-importer/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.4",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.3.4",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/platform-server": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@angular/service-worker": "^17.3.4",
    "@angular/ssr": "^17.3.4",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/pro-regular-svg-icons": "^6.5.1",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@sentry/angular": "^8.17.0",
    "@sentry/cli": "^2.32.1",
    "any-date-parser": "^1.5.4",
    "express": "^4.19.2",
    "fast-levenshtein": "^3.0.0",
    "lottie-web": "^5.12.2",
    "ngx-cookie-service": "^17.1.0",
    "ngx-infinite-scroll": "^17.0.0",
    "read-excel-file": "^5.7.1",
    "replace-in-file": "^7.1.0",
    "rxjs": "~7.8.1",
    "ssr-window": "^4.0.2",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.9",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@ngrx/schematics": "^17.2.0",
    "@types/express": "^4.17.21",
    "@types/fast-levenshtein": "^0.0.4",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.12.7",
    "@types/uuid": "^9.0.8",
    "autoprefixer": "^10.4.19",
    "daisyui": "^4.10.1",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier": "3.2",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
